/* eslint-disable prefer-regex-literals */
/* eslint-disable max-classes-per-file */
import { AvMicroserviceApi } from '@availity/api-axios';
import * as yup from 'yup';

export class AdminChangePasswordApi extends AvMicroserviceApi {
  constructor(config, username: string) {
    super({
      path: '/ms/api/availity/internal/mft/mft-onprem-api',
      ...config,
    });
    this._username = username;
  }

  async changePassword({ currentPassword, newPassword }) {
    const response = await this.put(
      { username: this._username, currentPassword, newPassword },
      { name: '/admin/user/password' }
    );

    return response.data;
  }
}

export class ExternalChangePasswordApi extends AvMicroserviceApi {
  constructor(config) {
    super({
      path: '/api',
      headers: {
        'X-Client-ID': 'test',
      },
      ...config,
    });
  }

  async changePassword({ currentPassword, newPassword }) {
    const response = await this.put(
      { currentPassword, newPassword },
      { name: '/user/password' }
    );

    return response.data;
  }
}

export class ForgotPasswordChangePasswordApi extends AvMicroserviceApi {
  constructor(config, passwordResetId: string) {
    super({
      path: '/api',
      headers: {
        'X-Client-ID': 'test',
      },
      ...config,
    });
    this._passwordResetId = passwordResetId;
  }

  async changePassword({ newPassword, username }) {
    const response = await this.post(
      { newPassword, username },
      { name: `/password/${this._passwordResetId}` }
    );

    return response.data;
  }
}

export const passwordConditions = (username: string) => [
  {
    message: 'Password must have at least 14 characters',
    passes: ({ newPassword, newPasswordTouched, submitted }) => {
      if (newPassword.length >= 14) {
        if (newPasswordTouched) return true;
        return null;
      }
      if (submitted) return false;
      return null;
    },
  },
  {
    message: 'Password cannot contain username',
    passes: ({ newPassword, newPasswordTouched, submitted }) => {
      if (new RegExp(`^((?!${username}).)*$`).test(newPassword)) {
        if (newPasswordTouched) return true;
        return null;
      }
      if (submitted) return false;
      return null;
    },
  },
  {
    message: 'Password must contain at least one special character',
    passes: ({ newPassword, newPasswordTouched, submitted }) => {
      // eslint-disable-next-line no-useless-escape, unicorn/better-regex
      if (new RegExp(/[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/).test(newPassword)) {
        if (newPasswordTouched) return true;
        return null;
      }
      if (submitted) return false;
      return null;
    },
  },
  {
    message: 'Password must have at least one number',
    passes: ({ newPassword, newPasswordTouched, submitted }) => {
      // eslint-disable-next-line unicorn/better-regex
      if (new RegExp('[0-9]').test(newPassword)) {
        if (newPasswordTouched) return true;
        return null;
      }
      if (submitted) return false;
      return null;
    },
  },
  {
    message: 'Password must have at least one uppercase letter',
    passes: ({ newPassword, newPasswordTouched, submitted }) => {
      if (new RegExp('[A-Z]').test(newPassword)) {
        if (newPasswordTouched) return true;
        return null;
      }
      if (submitted) return false;
      return null;
    },
  },
  {
    message: 'Password must have at least one lowercase letter',
    passes: ({ newPassword, newPasswordTouched, submitted }) => {
      if (new RegExp('[a-z]').test(newPassword)) {
        if (newPasswordTouched) return true;
        return null;
      }
      if (submitted) return false;
      return null;
    },
  },
  {
    message: 'Passwords must match',
    passes: ({ newPassword, confirmNewPassword, newPasswordTouched, confirmNewPasswordTouched, submitted }) => {
      if (newPassword === confirmNewPassword) {
        if (newPasswordTouched && confirmNewPasswordTouched) return true;
        return null;
      }
      if (submitted) return false;
      return null;
    },
  },
];


// Updated allowed special characters (similar to the pattern you've provided)
const allowedSpecialCharacters = "!\"#$%&'()+,./:;?@~-";

// Create master pattern for allowed characters (letters, numbers, underscore, whitespace, and the allowed special characters)
// eslint-disable-next-line unicorn/prefer-string-replace-all
const masterPattern = new RegExp(`^[\\s\\w${allowedSpecialCharacters.replace(/[$()*+./?[\\\]^{|}-]/g, '\\$&')}]*$`);

export const updatePasswordSchema = username => yup.object().shape({
  currentPassword: yup.string(),
  newPassword: yup
    .string()
    .min(14, 'Password must have at least 14 characters')
    .matches(new RegExp(`^((?!${username}).)*$`), 'Password cannot contain username')
    .matches(
      masterPattern,
      'Password contains an invalid character'
    )
    // eslint-disable-next-line unicorn/better-regex
    .matches(new RegExp('[0-9]'), 'Password must have at least one number')
    .matches(new RegExp('[A-Z]'), 'Password must have at least one uppercase letter')
    .matches(new RegExp('[a-z]'), 'Password must have at least one lowercase letter'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords do not match!')
    .required('Please confirm your password'),
});


