import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { Form, Field } from '@availity/form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { requestPasswordResetLink } from '../api';


type FormValues = {
  username: string | undefined;
};

const initialValues: FormValues = { username: undefined };

export const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);

  const handleSubmit = async (values) => {
    try {
      if (values.username) {
        setLoading(true);
        const { acknowledged } = await requestPasswordResetLink(values.username);
        if (acknowledged) {
          setInfo(`If we have an email on file for "${values.username}", we will contact you via email shortly. If you don't receive an email and you believe you should have, check your junk folder. Otherwise, call 1-800-AVAILITY for help resetting your password.`)
        } else {
          throw new Error('email not sent')
        }
      }
    } catch {
      setError('An error occurred requesting a password reset link')
    } finally {
      setLoading(false);
    }
  };

  return (
    <BlockUi blocking={loading}>
      <Alert toggle={() => setError(null)} isOpen={!!error} color="danger" >{error}</Alert>
      <Alert toggle={() => setInfo(null)} isOpen={!!info} color="info" >{info}</Alert>
      <h1 className="h4">Forgot Password</h1>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={yup.object().shape({
          username: yup.string().required('You must provide a username'),
        })}
      >
        <Field
          id="username"
          name="username"
          label="Username"
        />
        <Button block type="submit" color="primary">
          Submit
        </Button>
        <br />
        <Link to="/">Back to Login</Link>
      </Form>
    </BlockUi>
  );
};
