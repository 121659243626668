import React, { useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import BlockUi from 'react-block-ui';
import { v4 as uuidv4 } from 'uuid';
import { UncontrolledAlert as Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import Icon from '@availity/icon';
import Table, { DateCell, Cell } from '@availity/table';
import type { Column } from '@availity/table';
import Pagination, { PaginationControls } from '@availity/pagination';
import { avLogMessagesApiV2, avUserPermissionsApi } from '@availity/api-axios';
import '@availity/table/styles.scss';
import { useAppContext } from '@/context';
import { downloadFile, deleteFile, externalDownloadFile, externalDeleteFile, adminDownloadFile } from '@/api';
import type { FileNode, Folder } from '@/types';

type DeleteRequest = {
  name: string;
  customerId: string;
};

type DownloadRequest = {
  mailboxTarget: string;
  path: string;
  customerId: string;
};

async function downloadFileResponse(
  isExternal: boolean,
  path: string,
  customerId: string,
  isAdmin: boolean,
  mailboxTarget: string
) {
  let response;

  if (isExternal) {
    response = await externalDownloadFile(path, customerId);
  } else if (isAdmin) {
    response = await adminDownloadFile(mailboxTarget, path);
  } else {
    response = await downloadFile(path, customerId);
  }
  return response;
}

export const FileTable = ({
  isAdmin,
  files,
  mailboxTarget = '',
  itemsPerPage = 10,
  selectedFolder,
}: {
  isAdmin: boolean;
  files: FileNode[];
  mailboxTarget?: string;
  itemsPerPage?: number;
  selectedFolder: Folder;
}) => {
  const { customerId = '' } = useParams();
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const { isExternal } = useAppContext();
  const [fileDeleteSuccessAlert, setFileDeleteSuccessAlert] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileNode | null>(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [preview, setPreview] = useState('');

  const customerIdValue = useMemo(() => {
    if (customerId) {
      return customerId;
    }

    const targetParts = mailboxTarget.split('/');
    return targetParts.at(-1);
  }, [customerId, mailboxTarget]);

  const toggleDelete = useCallback(() => {
    setIsDeleteOpen(!isDeleteOpen);
  }, [isDeleteOpen]);

  const togglePreview = useCallback(() => {
    setIsPreviewOpen(!isPreviewOpen);
  }, [isPreviewOpen]);

  const {
    mutate: handleDeleteFile,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useMutation<AxiosResponse<{ message: string }> | null, unknown, DeleteRequest>(
    async ({ name, customerId }) => {
      // eslint-disable-next-line no-alert
      const deleteFn = isExternal ? externalDeleteFile : deleteFile;
      return deleteFn(name, customerId);
    },
    {
      onSuccess: (resp, request) => {
        if (resp) {
          // Fetch the files again after delete
          if (isExternal) {
            queryClient.invalidateQueries(['nodes', selectedFolder.data]);
            queryClient.invalidateQueries(['files', selectedFolder.data]);
          }
          queryClient.invalidateQueries(['nodes', selectedFolder.data, request.customerId]);
          queryClient.invalidateQueries(['files', selectedFolder.data, request.customerId]);
          setFileDeleteSuccessAlert(true);
        }
      },
    }
  );

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      handleDeleteFile({ name: fileToDelete.data, customerId: customerIdValue });
    }
    toggleDelete();
    setFileToDelete(null);
  };

  const deleteModal = (
    <Modal isOpen={isDeleteOpen} toggle={toggleDelete}>
      <ModalHeader toggle={toggleDelete}>Delete Confirmation</ModalHeader>
      <ModalBody>Are you sure you want to delete this file?</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={confirmDeleteFile}>
          Yes
        </Button>{' '}
        <Button color="secondary" onClick={toggleDelete}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );

  const previewModal = (
    <Modal isOpen={isPreviewOpen} toggle={togglePreview}>
      <ModalHeader toggle={togglePreview}>View File</ModalHeader>
      <ModalBody><pre id="file-preview">{preview}</pre></ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={togglePreview}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>

  );

  const items = useMemo(() => {
    // Subtract 1 because pages start at 1 not 0
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return files.slice(start, end);
  }, [files, itemsPerPage, page]);

  const {
    mutate: handleDownloadFile,
    isLoading: isDownloading,
    isError: isDownloadError,
  } = useMutation<void, unknown, DownloadRequest>(async ({ mailboxTarget, path, customerId }) => {
    const response = await downloadFileResponse(isExternal, path, customerId, isAdmin, mailboxTarget);

    // Create blob link to download
    const url = window.URL.createObjectURL(response.file);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', response.filename);

    // Append to html page
    document.body.appendChild(link);

    // Force download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  });

  const {
    mutate: handlePreviewFile,
    isLoading: isLoadingPreview,
    isError: isPreviewError,
  } = useMutation<void, unknown, { mailboxTarget: string; path: string; name: string }>(
    async ({ mailboxTarget, path }) => {
      const resp = await downloadFileResponse(isExternal, path, customerId, isAdmin, mailboxTarget);

      // Create blob link to open in new tab using content-type
      // @todo: constantize the allowed file types
      //
      const extension = resp?.filename?.split('.').pop();

      if (extension === 'zip' || extension === 'pdf') {
        handleDownloadFile({ mailboxTarget, path, customerId: customerIdValue });
      } else {
        const file = new Blob([resp.file], { type: resp.type });

        const fileContents = await file.text();
        setIsPreviewOpen(true);
        setPreview(fileContents);
      }
    }
  );

  const columns = useMemo<Column<FileNode>[]>(
    () => [
      {
        Header: 'File Name',
        accessor: 'name',
        defaultCanSort: true,
      },
      {
        Header: 'File Size',
        accessor: 'size',
        Cell: ({ value }) => (value > 1024 ? `${(value / 1024).toFixed(2)} MB` : `${value.toFixed(2)} KB`),
        defaultCanSort: true,
      },
      {
        Header: 'Last Modified',
        accessor: 'last_modified_on',
        Cell: DateCell({ dateFormat: 'MM/DD/YYYY h:mm a' }),
        defaultCanSort: true,
      },
      {
        Header: 'View',
        className: 'action-column',
        /* eslint-disable react/no-unstable-nested-components */
        Cell: ({ row }: Cell<FileNode>) => {
          const tooltipId = `id${uuidv4().replaceAll('-', '')}`;
          return (
            <>
              <Icon
                id={tooltipId}
                name="doc-alt"
                title="view file"
                tabIndex={0}
                style={{
                  cursor: row.original.type === 'pdf' || row.original.type === 'zip' ? 'not-allowed' : 'pointer'
                }}
                onClick={() => {
                  if (row.original.type !== 'pdf' && row.original.type !== 'zip') {
                    handlePreviewFile({ mailboxTarget, path: row.original.data, name: row.original.name });
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === 'enter' && row.original.type !== 'pdf' && row.original.type !== 'zip') {
                    handlePreviewFile({ mailboxTarget, path: row.original.data, name: row.original.name });
                  }
                }}
              />
              {row.original.type === 'pdf' || row.original.type === 'zip' ? (
                <UncontrolledTooltip placement="top" target={tooltipId}>
                  Please download this file to view its contents.
                </UncontrolledTooltip>
              ) : null}
            </>
          );
        },
      },
      {
        Header: 'Download',
        className: 'action-column',
        Cell: ({ row }: Cell<FileNode>) => {
          return (
            <Icon
              name="download-cloud"
              title="download file"
              tabIndex={0}
              onClick={() => {
                try {
                  avLogMessagesApiV2.info({
                    label: 'Download file',
                    event: 'download clicked',
                    filename: row.original.name,
                    filesize: row.original.size,
                  });
                } catch {
                  // noop
                }
                handleDownloadFile({ mailboxTarget, path: row.original.data, customerId: customerIdValue });
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  try {
                    avLogMessagesApiV2.info({
                      label: 'Download file',
                      event: 'download clicked',
                      filename: row.original.name,
                      filesize: row.original.size,
                    });
                  } catch {
                    // noop
                  }
                  handleDownloadFile({ mailboxTarget, path: row.original.data, customerId: customerIdValue });
                }
              }}
            />
          );
        },
      },
      ...(!isAdmin
        ? [
          {
            Header: 'Delete',
            className: 'action-column',
            Cell: ({ row }: Cell<FileNode>) => {
              if (isAdmin) return null;
              return (
                <Icon
                  name="trash-empty"
                  title="delete file"
                  tabIndex={0}
                  onClick={() => {
                    setFileToDelete(row.original);
                    toggleDelete();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      toggleDelete();
                    }
                  }}
                />
              );
            },
          },
        ]
        : []),
    ],
    [isAdmin, handlePreviewFile, mailboxTarget, handleDownloadFile, customerIdValue, toggleDelete]
  );

  const allColumnPermissions = columns.reduce((accum, column) => {
    if (Array.isArray(column.permissions) && column.permissions.length > 0) {
      for (const permission of column.permissions) {
        if (!accum.includes(permission)) {
          accum.push(permission);
        }
      }
    }
    return accum;
  }, []);

  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(
    ['permissions-config', allColumnPermissions],
    () => allColumnPermissions.length > 0 ? avUserPermissionsApi.getPermissions(allColumnPermissions) : Promise.resolve([{ data: { axiUserPermissions: [] } }])
  );

  const filteredColumns = !isLoadingPermissions
    ? columns.filter((column) => {
      if (Array.isArray(column.permissions) && column.permissions.length > 0) {
        return column.permissions.some((columnPermission) =>
          permissions.some((permission) => permission.id === columnPermission)
        );
      }
      return true;
    })
    : [];

  return (
    <>
      {previewModal}
      {deleteModal}
      {isPreviewError && <Alert color="danger">An error occurred viewing the file</Alert>}
      {isDownloadError && <Alert color="danger">An error occurred downloading the file</Alert>}
      {isDeleteError && <Alert color="danger">An error occurred deleting the file</Alert>}
      {fileDeleteSuccessAlert && (
        <Alert color="success" toggle={() => setFileDeleteSuccessAlert(false)}>
          The file has been deleted.
        </Alert>
      )}
      <BlockUi blocking={isLoadingPreview || isDeleting || isDownloading || isLoadingPermissions}>
        <Pagination page={page} onPageChange={setPage} items={files} itemsPerPage={itemsPerPage}>
          <Table sortable columns={filteredColumns} data={items} />
          <div className="d-flex justify-content-center mt-3">
            <PaginationControls
              showPaginationText
              directionLinks
              populatePaginationText={(lower, upper, total) => `${lower}-${upper} of ${total.toLocaleString()}`}
            />
          </div>
        </Pagination>
      </BlockUi>
    </>
  );
};
